import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
// import axios from 'axios'
// import sha256 from 'crypto-js/sha256'
// import parse from 'html-dom-parser'

import _isEmpty from 'underscore/cjs/isEmpty.js'
import _isObject from 'underscore/cjs/isObject.js'
import _isArray from 'underscore/cjs/isArray.js'

export const contentfulDocToHtml = doc => documentToHtmlString(doc)

// /*
// generate pages that needs to be registered on the proxy
// */
// export const generateProxyLists = async (enableArticleandProfile = false) => {
//   const postsProxyUrl = process.env.POSTS_PROXY_URL
//   const postsLinks = {
//     '/health-insurance/companies/': postsProxyUrl,
//     '/health-insurance/rate-rise-calculator/': postsProxyUrl,
//     '/health-insurance/quick/': postsProxyUrl
//   }

//   if (enableArticleandProfile) {
//     await axios.get(process.env.POSTS_LIST_SOURCE)
//       .then(response => {
//         if (!response.data) {
//           return
//         }
//         response.data.articles.forEach(article => {
//           postsLinks[article] = postsProxyUrl
//         })
//         response.data.profiles.forEach(profile => {
//           postsLinks[profile] = postsProxyUrl
//         })
//       })
//   }
//   return postsLinks
// }

export const strToCapitalize = str => {
  if (!str) {
    return str
  }

  return str.replace(/\b\w/g, l => l.toUpperCase())
}

export const getLocal = key => {
  if (import.meta.client) {
    return localStorage.getItem(key)
  }
  return null
}

/*
 * Set an item in localstorage
 * @param key
 * @param val
 */
export const setLocal = (key, val) => {
  if (import.meta.client) {
    localStorage.setItem(key, val)
  }
}

// /*
//  * Remove an item from local storage
//  * @param key
//  */
// export const removeLocal = key => {
//   if (process.client) {
//     localStorage.removeItem(key)
//   }
// }

// /*
//  * Icon Generating helper
//  * @param icon
//  * @returns {*|null}
//  */
// export const iconGenerator = icon => {
//   return icon ? require(`@/static/icons/${icon}`) : null
// }

// export const filterString = (str, replace) => {
//   return str.toLowerCase().replace(/ /g, replace)
// }

/*
 * transforms string to Kebab Case
 * @param str {String}
 */
export const strToKebabCase = str => {
  if (!str) {
    return str
  }

  return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-')
}

/*
 * transforms string to Snake Case
 * @param str {String}
 */
export const strToSnakeCase = str => {
  if (!str) {
    return str
  }

  return str
    .replace(/\s/g, '_')
    .replace(/[^a-z|0-9]/gi, '_')
    .replace(/\B([A-Z])/g, '_$1')
    .toLowerCase()
    .replace(/__/, '_')
}
/*
 * transforms string to Camel Case
 * @param str {String}
 */
export const strToCamelCase = str => {
  if (!str) {
    return str
  }

  return `${str}`.toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, function (match, val) {
      return val.toUpperCase()
    })
}

/*
 * generates a unique id
 * @param str {String}
 */
export const generateUID = str => (`${(Math.random(Date.now() * Math.random(100 + 50))).toString(32)}${Math.random().toString(16)}${str ? `-${strToKebabCase(str)}` : ''}`).replace(/\./g, '')

/**
 * Returns the value of key in the map
 * if the key doesn't exist it returns "default" key value or undefined
 * @param key {String}
 * @param obj {Object}
 */
export const getKey = (key, obj) => {
  const tempKey = Object.keys(obj).find(item => {
    if (item.includes(',')) {
      const tempItem = (item.includes(',') && item.split(',')
        .map(e => e.trim())) || ''
      return tempItem.includes(key)
    }

    return item === key
  }) ?? key

  return (tempKey in obj ? obj[tempKey] : (obj?.default || ''))
}

/*
 * check whether string contains html tag
 * @param str {String}
 * @returns {true|false}
 */
export const checkStringContainsHtml = str => {
  return /<\/?[a-z][\s\S]*>/i.test(str)
}

// /*
//  * check whether elemt on viewport
//  * @param element {HTML}
//  * @returns Boolean
//  */
// export const isInViewport = element => {
//   const SCROLL_TRIGGER = 80
//   if (!element) {
//     return false
//   }
//   const rect = element.getBoundingClientRect()

//   return (
//     rect.bottom - SCROLL_TRIGGER >= 0 &&
//     rect.right >= 0 &&
//     rect.top - SCROLL_TRIGGER <= (window.innerHeight || document.documentElement.clientHeight) &&
//     rect.left <= (window.innerWidth || document.documentElement.clientWidth)
//   )
// }

/**
 * remove falsey items in object
 * @param obj {Object}
 */
export const removeFalseyObj = obj => {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const item = obj[key]

      if (!item || _isEmpty(item)) {
        delete obj[key]
      }
    }
  }

  return obj
}

/*
 * generate excerpt
 * @param str {String}
 * @param maxLength {Int}
 * @returns Boolean
 */
export const generateExcerpt = (str, maxLength = 120) => {
  return (str && str.substring(0, maxLength) + (str.length > maxLength ? '...' : '')) || str
}

/*
 * remove html from string
 * @param str {String}
 * @returns String
 */
export const removeHtmlString = str => {
  if (!str) {
    return str
  }

  return str.replace(/<(.|\n)*?>/g, '').replace(/&amp;/g, '&')
}

// /*
//  * generate news object from contentful data
//  * @param object {Object}
//  * @param extendObj {Object}
//  * @returns Object
//  */
// export const generateNewsObjectFromContentful = (object, extendObj) => {
//   return {
//     title: object.title,
//     tag: object.vertical,
//     content: object?.mainContent ? generateExcerpt(removeHtmlString(documentToHtmlString(object.mainContent.json))) : 'Content',
//     authorName: object?.author ? object.author.name : 'Compare Club',
//     authorUrl: object?.author ? object.author.authorUrl : '/',
//     url: object.slug,
//     image: object?.featureImage ? object.featureImage.url : FEATURED_IMAGE_DEFAULT_IMAGE,
//     ...extendObj
//   }
// }

// /**
//  * returns formated number
//  * @param obj {String}
//  */
// export const formatPhoneNumber = number => {
//   return number
//     ? '+61' + number.substring(1)
//     : ''
// }

/**
 * transform Object into query url
 * @param Obj {Object}
 * @returns String
 */
export const transformObjectToQueryUrl = Obj => {
  return Object.keys(Obj).map(key => key + '=' + Obj[key]).join('&')
}

// /**
//  * generates a SHA256 hash
//  * @param text {String}
//  * @returns String
//  */
// export const hash256 = text => {
//   if (!text) {
//     return
//   }

//   text = `${text}`.toLowerCase()
//   return sha256(text).toString()
// }

/**
 * generates a validation result
 * @param Object
 * @returns Object
 */
export const generateValidation = ({ type = '', message = '' }) => {
  if (!['error', 'success'].includes(type)) {
    // eslint-disable-next-line no-console
    return console.error('type doesn\'t exist')
  }

  if (type === 'error') {
    return {
      success: { status: false },
      error: {
        status: true,
        message
      }
    }
  }

  return {
    success: { status: true },
    error: {
      status: false,
      message
    }
  }
}

/* flatten objects, and deep objects
 * @param obj {Object}
 * @param depth {String|Number}
 * @param parent {String}
 * @param newObj {Object}
 * @returns Object
 */
export const flattenObj = (object, depth = 'deep', parent, newObj = {}) => {
  if (_isEmpty(object)) {
    return {}
  }

  for (const key in JSON.parse(JSON.stringify(object))) {
    if (Object.hasOwnProperty.call(object, key)) {
      const item = object[key]
      const newKey = Object.keys(newObj).includes(key)
        ? `${parent}_${key}`
        : key

      if (typeof item === 'object' && !Array.isArray(item)) {
        if (depth > 0 || depth === 'deep') {
          const tempDepth = depth === 'deep'
            ? depth
            : depth - 1

          flattenObj(item, tempDepth, newKey, newObj)
        } else {
          newObj[newKey] = item
        }
      } else {
        newObj[newKey] = item
      }
    }
  }

  return newObj
}

/**
 * returns top and left positions of elements
 * @param el {HTMLElement}
 * @return obj {Object}
 */
export const getElOffset = el => {
  const rect = el.getBoundingClientRect()

  return {
    top: rect.top + window.pageYOffset,
    left: rect.left + window.scrollX,
    width: el.offsetWidth,
    height: el.offsetHeight
  }
}

/**
 * returns url with trailing slash
 * @param value {String}
 * @return value {String}
 */
export const trailingSlash = value => {
  if (!value) {
    return ''
  }
  value = value.toString()
  if (value.charAt(value.length - 1) !== '/') {
    value = value + '/'
  }
  return value
}

/**
 * returns true/false if payload is object
 * @param payload
 * @return {Boolean}
 */
export const isObject = payload => !_isArray(payload) && _isObject(payload)

/**
 * returns true/false if payload is array
 * @param payload
 * @return {Boolean}
 */
export const isArray = payload => _isArray(payload) && _isObject(payload)

/**
 * waits until timeout is resolved
 * @param time {Number}
 * example:
 *  await wait(300)
 */
export const wait = time => new Promise(resolve => setTimeout(resolve, time))

/**
 * returns element when its loaded in the window
 * @param elSelector {String}
 * @param all {Boolean}
 * example:
 *  (1) const el = await getElement('.test')
 *  (2) const el = await getElement('.test', true)
 */
export const getElement = async (elSelector, all = false) => {
  const selector = all ? 'querySelectorAll' : 'querySelector'

  while (!document[selector](elSelector)) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise(resolve => requestAnimationFrame(resolve))
  }

  return document[selector](elSelector)
}

// export const formatAmount = data => {
//   if (!data) {
//     return ''
//   }
//   const number = data.toString()
//     .replace(/[,]/g, '')
//     .split('.')
//   number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

//   return number.join('.')
// }

/**
 * returns class without extra spacecs, and false
 * @param str {String}
 * @return {String}
 */
export const removeClassExtras = str => str && str.replace(/false/g, ' ').replace(/\s\s+/g, ' ')

/**
 * checks this.$route is sales funnel
 * @param route {String}
 * @return {Boolean}
 */
export const pageIsFunnel = route => {
  const path = route?.path
  const params = route?.params
  const step = params?.step

  return !!(
    path?.includes('quote') ||
    path?.includes('form') ||
    step ||
    (path && path.match(/\/step/gi)) ||
    (path && path.match(/\/details\/$/)) ||
    (path && path.includes('/visitors-visa-ovhc/confirmation/'))
  )
}

// export const htmlParser = str => {
//   if (!str) {
//     return str
//   }

//   return parse(str)
// }

/**
 * Set an item in sessionStorage
 * @param key
 */
export const getSession = key => {
  if (import.meta.client) {
    return sessionStorage.getItem(key)
  }

  return null
}

/**
 * Set an item in sessionStorage
 * @param key
 * @param val
 */
export const setSession = (key, val) => {
  if (import.meta.client) {
    return sessionStorage.setItem(key, val)
  }

  return null
}

/*
 * Remove an item from local storage
 * @param key
 */
export const removeSession = key => {
  if (import.meta.client) {
    sessionStorage.removeItem(key)
  }
}

/**
 * handles parsing string to Object/Array
 * @param str {string}
 * @param _default {string} | {Object} | {Array}
 * @param showError {boolean}
 *
 * @return {Object} | {Array}
 * returns parsed string or default value
 */
export const parsify = (str, _default, showError = true) => {
  if ([null, undefined, 'null', 'undefined'].includes(str)) {
    return _default
  }

  const constructorName = str.constructor.name
  if (constructorName === 'Array' || constructorName === 'Object') {
    return JSON.parse(JSON.stringify(str))
  }

  try {
    return JSON.parse(str)
  } catch {
    // eslint-disable-next-line no-console
    showError && console.log(new Error(`Invalid JSON Format. Received ${str}`).stack)
    return _default
  }
}

export const getImageUrl = path => new URL(`${path}`, import.meta.url).href

/**
 * removes extra spaces and `false`
 * in css classes for cleaner DOM
 *
 * @param str {String}
 * @return string
 */
export const removeCssExtras = str => (str && str.replace(/false/g, ' ').replace(/\s\s+/g, ' ')) || ''

/*
 * Determine if the current environment is production
 */
export const isProduction = () => {
  const {
    environment
  } = useRuntimeConfig().public
  return environment === 'production'
}
